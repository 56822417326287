<template>
  <studio-card>
    <template slot-scope="{}">
      <payment
        :appointment_id="$route.params.appointment_id"
        :appointment_token_id="$route.params.appointment_token_id"
      />
    </template>
  </studio-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "TokenPayment",
  components: {
    StudioCard: () => import("@/components/inscription/studio/Card"),
    payment: () => import("@/components/inscription/customer/Payment"),
  },
};
</script>
